
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /*
| Developed by Dirupt
| Filename : _error.jsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/
import Error, { ErrorProps } from 'next/error';
import { captureUnderscoreErrorException } from '@sentry/nextjs';
const CustomErrorComponent = (props: ErrorProps) => {
    return <Error statusCode={props.statusCode}/>;
};
CustomErrorComponent.getInitialProps = async (contextData: any) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await captureUnderscoreErrorException(contextData);
    // This will contain the status code of the response
    return Error.getInitialProps(contextData);
};
const __Next_Translate__Page__19500f02eb1__ = CustomErrorComponent;

    export default __appWithI18n(__Next_Translate__Page__19500f02eb1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  